<template>
  <div class="nft container">
    <div class="title">NFT</div>
    <ul>
      <li>
        <div class="name">{{ $t('web.crowd.name1') }}</div>
        <div class="state">
          <span>{{ ispledge1 > 0 ? $t('app.nft.has') : $t('app.nft.not') }}</span>
          <img src="../../assets/img/angel-1.png">
        </div>
        <!--        <div class="tips">-->
        <!--          <span>{{ $t('app.nft.cast') }}</span>-->
        <!--          <span>{{ soldAmbassador }} / 300</span>-->
        <!--        </div>-->
        <div class="tips">
          <span v-if="ispledge1 > 0">{{$t('app.cooperation.quantity')}}  {{ AmbassadorAward | numFilter4}}</span>
          <span v-else>{{ $t('app.nft.signle', ['1800']) }}</span>
        </div>
            <div class="btn-receive" @click="getAmbassador" v-if="ispledge1> 0">{{$t('app.cooperation.receive')}}</div>
            <div class="btn-receive1" v-else>{{$t('app.cooperation.receive')}}</div>
      </li>
      <li>
        <div class="name">{{ $t('web.crowd.name2') }}</div>
        <div class="state">
          <span>{{ ispledge2 > 0 ? $t('app.nft.has') : $t('app.nft.not') }}</span>
          <img src="../../assets/img/angel-2.png">
        </div>
        <!--        <div class="tips">-->
        <!--          <span>{{ $t('app.nft.cast') }}</span>-->
        <!--          <span>{{ soldEnvoy }} / 200</span>-->
        <!--        </div>-->
        <div class="tips">
          <span v-if="ispledge2 > 0">{{$t('app.cooperation.quantity')}} {{EnvoyAward | numFilter4}}</span>
          <span v-else>{{ $t('app.nft.signle', ['3300']) }}</span>
        </div>
        <div class="btn-receive" @click="getEnvoy" v-if="ispledge2> 0">{{$t('app.cooperation.receive')}}</div>
        <div class="btn-receive1" v-else>{{$t('app.cooperation.receive')}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ethers } from 'ethers'
// import Community from '../../contracts/Community.json'
import { initSigner } from '../../utlis/index'
import Board from "../../contracts/Board.json"

export default {
  data() {
    return {
      address: '',
      contracts: {},
      total: 5190,      // 总可购买量
      limit: 0,         // 剩余可购买量
      product_1: 1800,
      product_2: 3300,
      soldAmbassador: 0,      // 当前已铸造多少大使
      soldEnvoy: 0,           // 当前已铸造多少特使
      haveAmbassador: false,  // 是否当次购买1800
      haveEnvoy: false,      // 是否当初购买3300
      AmbassadorAward: 0,
      EnvoyAward: 0,
      ispledge1:'',
      ispledge2:''

    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.initTimer)
  },
  methods: {
    async init() {
      let { address, provider, signer } = await initSigner()
      this.address = address

      // this.contracts.Community = new ethers.Contract(this.$common.Community, Community, provider).connect(signer)
      this.contracts.AmbassadorBoard = new ethers.Contract(this.$common.AmbassadorBoard, Board, provider).connect(signer)
      this.contracts.EnvoyBoard = new ethers.Contract(this.$common.EnvoyBoard, Board, provider).connect(signer)

      this.getData()
      window.contracts = this.contracts;
      clearInterval(this.initTimer)
      this.initTimer = setInterval(() => {
        this.getData()
      }, 20000);
    },
    async getData() {
      if (!this.address) return false
      // 获取剩余可购买量
      // this.contracts.Community.getLimitByAddress(this.address).then(res => {
      //   // console.log(res);
      //   let num = ethers.utils.formatUnits(res, 18)
      //   this.limit = num
      // })
      // this.contracts.Community.soldAmbassador().then(res => {
      //   // let num = ethers.utils.formatUnits(res, 18)
      //   this.soldAmbassador = Number(res)
      //   console.log(this.soldAmbassador);
      //
      // })
      // this.contracts.Community.soldEnvoy().then(res => {
      //   // let num = ethers.utils.formatUnits(res, 18)
      //   this.soldEnvoy = Number(res)
      // })
      // this.contracts.Community.haveAmbassador(this.address).then(res => {
      //   this.haveAmbassador = res
      // })
      // this.contracts.Community.haveEnvoy(this.address).then(res => {
      //   this.haveEnvoy = res
      // })

      this.contracts.AmbassadorBoard.earned(this.address).then(res => {
        this.AmbassadorAward = ethers.utils.formatUnits(res, 18)
      })
      this.contracts.EnvoyBoard.earned(this.address).then(res => {
        this.EnvoyAward = ethers.utils.formatUnits(res, 18)
      })
      this.contracts.AmbassadorBoard.balanceOf(this.address).then(res => {
        console.log(ethers.utils.formatUnits(res, 0));
        this.ispledge1 = ethers.utils.formatUnits(res, 0)
      })
      this.contracts.EnvoyBoard.balanceOf(this.address).then(res => {
        console.log(ethers.utils.formatUnits(res, 0));
         this.ispledge2 = ethers.utils.formatUnits(res, 0)
      })
    },
    // 大使领取
    getAmbassador() {
      if (this.AmbassadorAward <= 0) return this.$message.error(this.$t('app.cooperation.No'))
      this.contracts.AmbassadorBoard.getReward().then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.receiveCG'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.receiveSB'))
      });
    },
    getEnvoy() {
      if (this.EnvoyAward <= 0) return this.$message.error(this.$t('app.cooperation.No'))
      this.contracts.EnvoyBoard.getReward().then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.receiveCG'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.receiveSB'))
      });
    },


  }
}
</script>

<style lang="scss" scoped>
.nft {
  padding: 1.4rem 0.44rem 0.4rem;
  .title {
    width: 5.74rem;
    height: 1.66rem;
    line-height: 1.28rem;
    font-size: 0.36rem;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    background: url("../../assets/img/web-title-bg.png") left top / 100% 100%
      no-repeat;
  }
  ul {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    li {
      width: 47%;
      text-align: center;
      font-size: 0.24rem;
      .name {
        width: 2.32rem;
        height: 0.62rem;
        line-height: 0.62rem;
        margin: 0 auto 0.2rem;
        text-align: center;
        background: url("../../assets/img/crowd-btn.png") left top / 100% 100%
          no-repeat;
      }
      .state {
        position: relative;
        span {
          position: absolute;
          bottom: 0.2rem;
          left: 50%;
          transform: translateX(-50%);
          font-size: 0.24rem;
          white-space: nowrap;
        }
        img {
          width: 2.98rem;
          height: 3.9rem;
          margin: 0 auto 0.2rem;
        }
      }
      .tips {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.2rem;
      }
      .btn{
        display: flex;
        justify-content: space-between;
      }
      .btn-receive {
        width: 2.8rem;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 00.28rem;
        background-color: rgba(178, 133, 237, 0.8);
        border: 1px solid #45bbf3;
        border-radius: 0.08rem;
        color: #fff;
      }
      .btn-receive1 {
        width: 2.8rem;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 00.28rem;
        background-color: rgba(190, 190, 190, 0.8);
        border-radius: 0.08rem;
        color: #fff;
      }
    }
  }
}
</style>
